<template>
  <div class="pt-nav">
    <UserBackground :userId="userId" />
    <div class="user-wrap">
      <!-- 用户卡片 -->
      <UserCard :type="'2'" :userInfo="agentInfo"  @handleEditClick="handleEditAgentInfo" />

      <div class="line"></div>

      <!-- 相册 -->
      <UserPhotos :photoList="photoList" @editPhotos="submitEditPhotos" />
    </div>

  </div>
</template>

<script>
import UserBackground from '../../components/user/UserBackground.vue'
import UserPhotos from '../../components/user/UserPhotos.vue'
import UserCard from '../../components/user/UserCard.vue'
import { queryAgentInfoById, editAgentPhotos } from '@/service/agent'
export default {
  name: 'AgentDetail',
  components: { UserBackground, UserCard, UserPhotos },
  data() {
    return {
      userId: '',
      agentInfo: {},
      photoList: [],
      bgImg: ''
    }
  },
  methods: {
    async init() {
      this.userId = this.$route.query.userId
      if (this.userId) {
        const params = {
          userId: this.userId
        }
        const resp = await queryAgentInfoById(params)
        if (resp.code === 200) {
          this.agentInfo = resp.data
          console.log('agentDetail agentInfo=', this.agentInfo)
          const imgUrl = this.agentInfo.backgroundPhoto ? this.agentInfo.backgroundPhoto : this.agentInfo.headPhoto
          this.bgImg = this.$gbUtils.formatImgUrl(imgUrl)
          this.formatData()
        } else {
          console.log('getAgentInfo Error, errMsg=', resp.msg)
          this.$toast('获取代理资源用户信息错误')
        }
      }
    },
    // 相册修改
    async submitEditPhotos(photos) {
      const params = {
        userId: this.agentInfo._id,
        photos: photos
      }
      const resp = await editAgentPhotos(params)
      if (!resp.success) {
        // 重置相册数据
        this.$nextTick(() => {
          this.photoList = []
          this.formatData()
        })
        return this.$toast(this.$t('common.editFail'))
      }
    },
    formatData() {
      // 图片处理
      if (this.agentInfo.photos && this.agentInfo.photos !== '') {
        this.photoList = (this.agentInfo.photos).split(',').map(item => {
          return {
            url: this.$gbUtils.formatImgUrl(item)
          }
        })
      }
    },
    handleEditAgentInfo() {
      this.$router.push({ path: '/agentEdit', query: { userId: this.agentInfo._id } })
    }
  },
  mounted() {
    this.init()
  }
}
</script>


<style lang="scss" scoped></style>