<template>
  <div class="photos-wrap">
    <p>{{ $t('userDetail.album') }}</p>
    <VanUploader v-model="fileList" :after-read="afterRead" :before-delete="deletePhoto" :deletable="true" :max-count="9"
      multiple accept="image/*">
      <VanIcon name="plus" />
    </VanUploader>
  </div>
</template>

<script>
import { uploadFile } from '@/service/upload'

export default {
  name: '',
  props: {
    photoList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      fileList: [],
    }
  },
  methods: {
    async uploadImage(file) {
      let url = ''
      const resp = await uploadFile(file, 'image')
      if (resp.success) {
        url = resp.s3Key
      }
      return url
    },
    async afterRead(files) {
      const tempList = this.$_.cloneDeep(this.photoList)
      let photos = tempList.map(item => {
        return this.$gbUtils.removeImgUrlPrefix(item.url)
      })
      if (files.length !== undefined) { //多张图片
        for (let i = 0; i < files.length; i++) {
          files[i].status = 'uploading'
          files[i].message = '上传中...'
          let url = await this.uploadImage(files[i].file)
          files[i].status = 'done'
          if (url) {
            photos.push(url)
          } else {
            this.fileList.splice(this.fileList.length - 1, 1)
          }
        }
      } else {
        // 单张图片
        files.status = 'uploading'
        files.message = '上传中...'
        let url = await this.uploadImage(files.file);
        console.log(url);
        files.status = 'done'
        if (url) {
          photos.push(url)
        } else {
          // this.fileList.splice(this.fileList.length - 1, 1)
        }
      }
      photos = photos.join(',');
      // 传递给父组件
      this.$emit('editPhotos', photos)
    },
    async deletePhoto(file, detail) {
      const tempList = this.$_.cloneDeep(this.fileList)
      tempList.splice(detail.index, 1)
      let photos = tempList.map(item => {
        return this.$gbUtils.removeImgUrlPrefix(item.url)
      })
      photos = photos.join(',')
      // 传递给父组件
      this.$emit('editPhotos', photos)
    }
  },
  watch: {
    photoList(newVal, oldVal) {
      this.fileList = newVal
    }
  },
  mounted() {
    this.fileList = this.photoList
  }
}
</script>

<style scoped lang="scss">
.photos-wrap {
  width: 100%;
  background: $themeBgColor;
  padding: 20px;

  p {
    font-size: 16px;
    color: #fff;
    font-size: 14px;
    padding-bottom: 10px;
  }

  .van-uploader {
    width: 100%;
  }

  /deep/ .van-uploader__wrapper {
    // min-height: 284px;
    max-height: 460px;
    overflow-y: auto;

    >div {
      margin-bottom: 8px;
    }
  }

  /deep/ .van-uploader__upload,
  /deep/ .van-uploader__preview {
    width: 30%;
    min-height: 105px;
    border-radius: 6px;
    margin-right: 10px;
  }

  /deep/ .van-image {
    width: 100%;
    object-fit: cover;
    border-radius: 6px;
    min-height: 105px;
  }

  /deep/ .van-uploader__input-wrapper {
    width: 30%;
    min-height: 105px;
    text-align: center;
    background: rgba(0, 0, 0, 0.9);
    border-radius: 6px;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    order: 0;
    margin-right: 10px;

    .van-icon-plus {
      margin-top: calc(50% - 10px);
    }
  }

  /deep/ .van-uploader__preview-delete {
    width: 22px;
    height: 22px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /deep/ .van-uploader__preview-delete-icon {
    -webkit-transform: unset !important;
    transform: unset !important;
    position: unset;
  }
}
</style>
